import { toast } from "react-toastify";

export const options = {
    // onOpen: props => console.log(props.foo),
    // onClose: props => console.log(props.foo),
    autoClose: 6000,
    // closeButton: FontAwesomeCloseButton,
    // type: toast.TYPE.INFO,
    hideProgressBar: false,
    position: "top-left",
    pauseOnHover: true,
    transition: CSSTransition,
    progress: 0.2
    // and so on ...
};