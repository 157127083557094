export const FormatFile = {
    word: ["doc", "docx", "docm", "dotx", "dotm", "rtf"],
    excel: [
        "xls",
        "xlt",
        "xlm",
        "xlsx",
        "xlsm",
        "xltx",
        "xltm",
        "xlsb",
        "xla",
        "xlam",
        "xll",
        "xlw",
    ],
    powerpoint: [
        "ppt",
        "ppt",
        "pot",
        "pps",
        "ppa",
        "ppam",
        "pptx ",
        "pptm",
        "potx",
        "potm",
        "ppam",
        "ppsx",
        "ppsm",
        "sldx",
        "sldm",
        "pa",
    ],
    zip: ["zip", "rar", "7z", "gzip"],
    video: ["mp4", "ogv", "webm"],
    music: ["mp3", "ogg", "wav"],
}