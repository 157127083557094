import React from 'react'
import logo_nat from 'shared/assets/image/8597dd2306dcc3c4fbaec363c10c6de7.png'
import './National.scss'
export const National = () => {
    return (
        <div className='National'>
            <a href="https://www.guzkod.ru/readnews/63f2e6a7a4906e4a6ae13f54">
                <img src={logo_nat} alt="" />
            </a>

        </div>
    )
}
