

type Props = {
    Text:string 
}

export const Heading = ({Text}: Props) => {
  return (
    <h1>{Text}</h1>

  )
}